/* Custom Styles */
ul,
li,
a,
button,
table,
td,
th,
.MuiButton-root {
  font-family: "Happy Monkey", cursive !important;
}
button.nft_create_btn {
  border: 2px solid #17a0bb;
  width: 90px;
  padding: 12px 52px;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 20px;
}
button.nft_create_btn.active {
  background-color: #17a0bb;
  color: #fff;
}
.create_para {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
.faq_panel .accordian_head h2 {
  font-size: 17px;
  margin: 0;
}
.faq_panel .MuiAccordion-root {
  background: transparent;
  box-shadow: none;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 !important;
}
.faq_panel .MuiAccordionSummary-root {
  min-height: unset !important;
}
.faq_panel .MuiAccordion-root:before {
  display: none;
}
.faq_panel .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}
.single_collectible .item {
  display: inline-block;
  background: #fff;
  padding: 1em;
  margin: 0 0 1.5em;
  width: 100%;
  -webkit-transition: 1s ease all;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 10%);
  border-radius: 15px;
  margin-top: 10px;
}
.primary_label {
  font-size: 15px;
  font-weight: 600;
  color: #3f3f3f;
  margin: 0;
}
.form_note {
  font-size: 13px;
  color: #969696;
}
.file_btn {
  position: relative;
  overflow: hidden;
}
input.inp_file {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
  z-index: 1 !important;
}
.toggle_custom.switch input {
  display: none;
}
.toggle_custom.switch {
  display: inline-block;
  width: 60px;
  height: 30px;
  transform: translateY(50%);
  position: relative;
}
.toggle_custom .slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #17a0bb, 0 0 4px #17a0bb;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.4s;
  background: #ededed;
}
.toggle_custom .slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #141259;
  border-radius: 30px;
  transform: translateX(-30px);
  transition: 0.4s;
}
.toggle_custom input:checked + .slider:before {
  transform: translateX(30px);
  background: #fff;
}
.toggle_custom input:checked + .slider {
  box-shadow: 0 0 0 2px #17a0bb, 0 0 2px #17a0bb;
  background: #141259;
}
.form-control,
.is-focused .form-control {
  background-image: linear-gradient(
      to top,
      #141259 2px,
      rgba(156, 39, 176, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.primary_inp {
  font-size: 14px;
  color: #141259;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  width: 100%;
  height: calc(2.4375rem + 2px);
  padding: 10px 15px;
  background: #efefef !important;
  font-weight: 600;
}
.primary_inp::placeholder {
  font-size: 14px;
}
.inp_grp {
  position: relative;
}
.inp_append {
  position: absolute;
  margin: 0;
  top: 4px;
  right: 10px;
  color: #969696;
}
button.create_btn {
  font-size: 16px;
  color: #141259 !important;
  text-transform: capitalize;
  font-weight: 600;
  background-color: transparent !important;
  border: 2px solid #141259;
  border-radius: 30px;
}
.primary_modal {
  z-index: 1150;
}
.primary_modal .modal-dialog .modal-header {
  display: block;
}
.primary_modal .modal-header .close {
  float: none;
  position: absolute;
  color: #141259;
  top: 5px;
  right: 12px;
  font-size: 32px;
}
.primary_modal h5.modal-title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}
.hex {
  position: relative;
  max-width: 100%;
  width: 85px;
}
.hex:before {
  content: "";
  display: block;
  padding-bottom: 70%;
}
.hex img {
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 115%;
}
.hex__shape {
  position: absolute;
  backface-visibility: hidden;
  left: 0;
  height: 100%;
  overflow: hidden;
  top: 0;
  transform: rotate(240deg);
  width: 100%;
}
.font_16 {
  font-size: 16px;
}
.font_600 {
  font-weight: 600;
}
.line_20 {
  line-height: 20px;
}
.holder {
  height: 200px;
  width: 192px;
  border: 2px solid black;
  margin: 0 auto;
}
.holder img {
  max-width: 192px;
  max-height: 200px;
  min-width: 192px;
  min-height: 200px;
}
input[type="file"]#photo {
  margin-top: 5px;
  opacity: 0;
  z-index: 999;
}
.profile_edit_panel {
  position: relative;
}
.profile_edit_icon {
  position: absolute;
  top: -3px;
  z-index: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.profile_edit_icon i {
  background: #141259;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 3px solid #fff;
}
.primary_tab {
  display: flex;
}
.proposal_panel {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e7e7e7;
}
.proposal_panel_overall .proposal_panel:last-child {
  border-bottom: none;
}
.proposal_panel_content {
  margin-left: 30px;
}
.proposal_panel_content h3 {
  color: #272727;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}
.proposal_panel_content p {
  color: #000;
  font-size: 16px;
  margin: 0;
}
.proposal_panel_content p span {
  color: #969696;
}
.proposal_panel .badge {
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 600;
  width: 110px;
  border-radius: 30px;
  text-align: center;
  text-transform: capitalize;
}
.proposal_panel .badge_active {
  background-color: #05c38b;
}
.proposal_panel .badge_closed {
  background-color: #e20185;
}
.proposal_panel .badge_pending {
  background-color: #053fc3;
}
button.btn_outline_red {
  background-image: linear-gradient(90deg, transparent 0, transparent);
  color: #141259 !important;
  height: 40px;
  border-radius: 30px;
  padding: 15px 28px;
  transition: 0.2s all;
  border: 2px solid #141259;
  text-transform: capitalize;
  font-size: 16px;
}
button.btn_outline_red:hover {
  background-image: linear-gradient(90deg, #141259 0, #141259);
  color: #fff !important;
}
.items_bg {
  min-height: 200px;
}
.items_header {
  padding-top: 100px !important;
}
.items_bg_pink {
  background: #f0fafe;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.items_profile {
  margin-top: -160px;
  border-radius: 50%;
}
.item_prof {
  position: relative;
}
.item_prof_panel {
  position: absolute !important;
  top: -20px !important;
}
.item_prof input[type="file"] {
  max-width: 100%;
}
.address_text {
  color: #32302d;
  font-size: 25px;
  font-weight: 700;
}
.not_found_text {
  color: #32302d;
  font-size: 30px;
  font-weight: 700;
}
.not_found_text_sub {
  color: #714e4c !important;
  font-size: 16px;
  font-weight: 600;
}
.notes_fa {
  padding-left: 15px;
  color: #141259;
}
.btn_outline_red {
  background-image: linear-gradient(90deg, transparent 0, transparent);
  color: #969696 !important;
  height: 40px;
  border-radius: 30px;
  padding: 10px 28px;
  transition: 0.2s all;
  border: 2px solid #141259;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
}
.btn_outline_red:hover {
  background-image: linear-gradient(90deg, #141259 0, #141259);
  color: #fff !important;
}
.bg_red_icon {
  background-color: transparent;
  border: 2px solid #141259;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #969696;
  margin-left: 15px;
  width: 40px;
  height: 40px;
  transition: 0.2s all;
  cursor: pointer;
}
.bg_red_icon:hover {
  background-color: #141259;
  color: #fff;
}
.items_tab .nav-link {
  text-align: center;
  line-height: 20px;
}
.items_tab .nav-link .tab_head {
  color: #969696 !important;
  margin-bottom: 8px;
}
.items_tab .nav-link .tab_count {
  color: #000 !important;
}
.items_tab .nav-link.active .tab_head {
  color: #fff !important;
}
.items_tab .nav-link.active .tab_count {
  color: #fff !important;
}
.items_tab {
  align-items: center;
  justify-content: center;
}
.flex_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.follow_media {
  align-items: center;
}
.media_num {
  color: #9a9696;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0px;
}
.media_text {
  color: #4a4a4a;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.following_row .single_collectible .item {
  border-radius: 5px !important;
}
.following_row {
  justify-content: center;
}
.items_bg img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  max-height: 200px;
  min-height: 200px;
}
.edit_cover_text {
  position: absolute;
  right: 20px;
  bottom: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: 2px solid #141259;
  background-color: transparent;
  transition: 0.2s all;
  opacity: 0;
  border-radius: 30px;
  padding: 3px 15px;
}
.items_bg:hover .edit_cover_text {
  opacity: 1;
}
.edit_cover_text:hover {
  color: #141259;
  background-color: #fff;
}
.edit_cover_container {
  position: relative;
}
.custom_filter {
  display: flex;
  align-items: center;
}
.custom_filter select {
  width: auto;
  border: none;
  background: none;
  box-shadow: none !important;
  color: #969696;
}
.custom_filter i {
  color: #141259;
}
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  position: relative;
}
.info_header {
  padding-top: 97px;
  padding-bottom: 0px;
}
.info_row {
  min-height: calc(100vh - 307px);
  height: 100%;
}
.bg_pink {
  box-shadow: -5px 0 15px 0px rgb(0 0 0 / 10%);
  padding: 25px;
  background: #fff;
}
.info_title {
  color: #3d3737;
  font-size: 25px;
  font-weight: 700;
}
.info_h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.info_h3 span {
  color: #000;
}
.info_des {
  color: #6b6b6b;
  font-size: 14px;
  padding-top: 15px;
}
.info_media {
  border-bottom: 1px solid #141259;
  padding: 20px 15px 20px 0px;
}
.flex_txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.prce_red {
  color: #17a0bb;
  padding-right: 20px;
  font-size: 20px;
  font-weight: 700;
}
.prce_grey {
  color: #9a9696;
}
.prce_grey p {
  line-height: 20px;
}
.prce_grey .price_1 {
  font-weight: 500;
  font-size: 14px;
}
.prce_grey .price_2 {
  font-weight: 700;
  font-size: 16px;
}
.owner_card {
  border-radius: 30px;
  border: 1px solid #141259;
}
.media_text_big_1 {
  font-size: 16px;
  color: #9a9696;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
.media_text_big_1 .text_blk {
  color: #4a4a4a;
  font-weight: 700;
}
.media_text_big_2 .text_blk {
  color: #4a4a4a;
  font-size: 18px !important;
}
.media_text_big_2 {
  font-size: 20px;
  color: #9a9696;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}
.info_big_card {
  border-radius: 25px;
}
button.btn_outline_red {
  font-weight: 600;
  font-size: 16px;
}
.info_big_card button.btn_outline_red {
  min-width: 125px;
}
.proposal_panel_overall .info_media:last-child {
  border-bottom: none !important;
}
.info_tab .nav-tabs.masonry_tab .nav-link {
  padding: 0 10px !important;
  font-size: 14px !important;
  color: #000;
  font-weight: 600;
}
.info_tab .nav-tabs.masonry_tab .nav-link.active {
  color: #141259;
  border-color: #141259;
}
.info_tab .nav-tabs.masonry_tab {
  border-bottom: 1px solid #e9e9e9 !important;
  border-radius: 0;
  padding: 0;
}
.arrow_expand .fas {
  color: #9a9696;
  font-size: 25px;
}
.img_des {
  text-align: center;
  margin-top: 20px;
  display: none;
}
.arrow_expand .fas {
  position: absolute;
  top: 20px;
  right: 0px;
}
.arrow_expand {
  padding: 20px;
}
#image_div.expand_img {
  flex: 0 0 100%;
  max-width: 100%;
}
#img_des.show_des {
  display: block;
}
#detai_div.hide_detail {
  display: none;
}
#arrow_icon {
  cursor: pointer;
}
#arrow_icon.fa-shrink:before {
  content: "\f065" !important;
}
.buy_title_sm {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 700;
}
.buy_desc_sm {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
  font-weight: 600;
}
.buy_desc_sm_bold {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
  font-weight: 700;
}
.buy_title_md {
  color: #000;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.bor_bot_modal {
  border-bottom: 1px solid #141259;
}
.approve_text {
  color: #ffffff;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}
.approve_desc {
  color: #2b2b2b;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0px;
}
.approve_media .fas {
  font-size: 25px;
}
.approve_media .fas.pro_complete {
  color: #17a0bb;
}
button:disabled {
  color: #ffffffa1 !important;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner_icon {
  -webkit-animation: spinner 1s linear infinite;
  animation: spinner 1s linear infinite;
  font-size: 25px;
}
.inner_div_info {
  max-height: 265px;
  overflow-y: auto;
}
.purchase_text {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}
.purchase_desc {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.place_bit_desc {
  color: #000;
  font-weight: 700;
  font-size: 16px;
}
.place_bit_desc_2 {
  color: #402c2d;
  font-weight: 700;
  font-size: 16px;
}
.place_bit_desc_2 .text_red {
  font-size: 20px;
  font-weight: 700;
  color: #141259;
}
.input_grp_style_1 .input-group-text {
  border-bottom-color: #decacb !important;
  height: 35px !important;
  font-weight: 700;
}
.input_grp_style_1 input {
  border-bottom-color: #decacb !important;
  background-image: linear-gradient(
      to top,
      #decacb 2px,
      rgba(156, 39, 176, 0) 2px
    ),
    linear-gradient(to top, #decacb 1px, rgba(210, 210, 210, 0) 1px) !important;
}
.input_grp_style_1 input::placeholder {
  color: #aca4a1 !important;
  font-weight: 600;
}
.label_muted {
  color: #bba3a6;
}
.bid_form label {
  color: #5e4a4b;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  width: 100%;
  margin-bottom: 0px;
}
#bid {
  color: #000;
  font-weight: 600;
}
#bid::placeholder {
  color: #000 !important;
}
#image_div_owner.expand_img {
  flex: 0 0 100%;
  max-width: 100%;
}
#img_des_owner.show_des {
  display: block;
}
#detai_div_owner.hide_detail {
  display: none;
}
#arrow_icon_owner {
  cursor: pointer;
}
#arrow_icon_owner.fa-shrink:before {
  content: "\f065" !important;
}
.expand_img img {
  width: 100%;
}
.img_accept img {
  max-width: 150px;
  max-height: 150px;
  margin: 0 auto;
}
.accept_desc {
  line-height: 20px;
  margin-top: 20px;
}
/* .btn_header {
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  width: calc(100% - 30px);
}
.btn_header>span {
  justify-content: center !important;
} */
/* button.btn_outline_red {
  background-color: transparent !important;
}
button.create_btn:hover {
  background-color: transparent !important;
} */
.login_form label {
  color: #785556;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0px;
}
.login_form input::placeholder {
  color: #aca4a1 !important;
  font-weight: 600;
}
.pwd_link {
  text-decoration: underline;
  color: #867e7c;
  font-weight: 700;
  cursor: pointer;
}
.pwd_link:hover {
  color: #141259 !important;
}
.create_link {
  text-decoration: underline;
  color: #141259;
  font-weight: 700;
  cursor: pointer;
}
.create_link:hover {
  color: #867e7c !important;
}
.forgot_desc {
  color: #785556;
  font-weight: 600;
  margin-bottom: 20px;
}
.reg_desc {
  color: #977878;
}
.privacy_link {
  color: #785556;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}
.privacy_link:hover,
.privacy_link:focus {
  color: #141259 !important;
  text-decoration: underline;
}
.terms_des,
.terms_ul li {
  color: #000;
  font-size: 16px;
}
.dur_text {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px !important;
}
.filter_dropdown {
  justify-content: flex-end;
}
.filter_dropdown button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.filter_menu {
  padding: 15px;
  min-width: 8rem !important;
  border-radius: 10px !important;
}
.filter_menu > div {
  cursor: pointer;
}
.filter_menu > div:hover {
  color: #17a0bb;
}
.filter_menu .custom-control-label {
  padding-left: 20px;
  height: 22px;
  display: flex;
  align-items: center;
  color: #737373;
  font-weight: 700;
}
.filter_menu .custom-control-label::before {
  background-color: #9f9f9f;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #141259;
  border-color: #141259;
}
.filter_menu .custom-checkbox {
  padding-left: 0px;
}
.filter_btn {
  background-color: transparent !important;
  color: #787878 !important;
  font-size: 18px;
  border: none !important;
  padding: 0px !important;
  box-shadow: 0 0px 0px 0 rgb(250 250 250 / 14%),
    0 0px 0px -0px rgb(250 250 250 / 20%), 0 0px 0px 0 rgb(250 250 250 / 12%) !important;
}
.noti_head {
  color: #000;
  text-align: center;
  font-weight: 700;
  padding-top: 10px;
  font-size: 18px;
}
.no_noti {
  color: #9a9696;
  font-size: 14px;
  text-align: center;
  padding: 20px 0px;
  margin-bottom: 0px;
}
.see_all_link {
  font-size: 15px;
  font-weight: 600;
  color: #141259 !important;
  width: 100%;
  text-align: center;
  text-decoration: underline !important;
}
.see_all_link:hover {
  color: #787878 !important;
}
.noti_ul .makeStyles-caret-65 {
  display: none !important;
}
.title_div {
  padding: 10px 0px;
  border-bottom: 1px solid #c3bbbb47;
}
.title_discussion {
  color: #694544;
  font-weight: 600;
}
.list_grp_style_1 .list-group-item :first-child {
  margin-right: 0px !important;
}
.list_grp_style_1 .list-group-item {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100%;
  display: block;
  border-bottom: 1px solid #c3bbbb47;
}
.cat_title {
  color: #694544;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}
.cat_desc {
  font-size: 14px;
  color: #796463;
  font-weight: 600;
  line-height: 25px;
}
.cat_link {
  font-size: 15px;
  color: #796463;
  font-weight: 700;
  line-height: 25px;
}
.cat_link_green {
  color: #76d238 !important;
}
.cat_link_blue {
  color: #6076e0 !important;
}
.cat_dur {
  color: #443f40;
  font-weight: 600;
}
.masonry_tab.dis_tab {
  border-color: #c3bbbb47 !important;
  border-bottom-width: 1px !important;
}
.creators_details_td img {
  width: 35px;
  margin-right: 3px;
}
.sel_period {
  background: #ffffff69 !important;
  padding-left: 15px;
  padding-right: 15px;
  color: #969696 !important;

  box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 10%) !important;
  max-width: 270px;
  border-radius: 30px;
}
.table_style_1 th {
  color: #969696 !important;
  font-weight: 600 !important;
}
.table_style_1 td {
  font-weight: 600 !important;
}
.table_style_1 th:first-child,
.table_style_1 td:first-child {
  min-width: 200px;
}
.table_style_1 th:nth-child(2),
.table_style_1 td:nth-child(2) {
  min-width: 175px;
}
.cat_title_sm {
  font-size: 18px !important;
}
.dis_head .inner_title {
  align-items: baseline;
}
.icon_red {
  color: #141259;
}
.dis_accordion > div[role="button"] {
  background-color: #f0fafe;
}
.dis_accordion {
  border-bottom: none !important;
}
.m-0.panel_header_dis > div {
  margin: 0px !important;
  padding: 5px 0px !important;
}
.text_gry {
  color: #969696;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.panel_header_dis {
  align-items: baseline !important;
  margin-top: 15px !important;
}
.accordian_para {
  font-weight: 500;
  color: #000;
  line-height: 25px;
  width: 100%;
  font-size: 14px;
}
.desc_discussion {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  padding-top: 15px;
}
.reply_uer_name {
  font-size: 16px;
  color: #000;
  font-weight: 700;
}
.reply_txt {
  font-size: 20px;
  font-weight: 600;
  color: #3d2524;
  margin: 0;
  line-height: 30px;
}
.accordion_form {
  background-color: #f0fafe;
  margin-top: 20px;
}
.post_link {
  font-size: 15px;
  color: #594c4b;
  font-weight: 700;
  line-height: 25px;
  cursor: pointer;
}
.post_link:hover,
.post_link.active,
.cat_link:hover {
  color: #141259 !important;
  text-decoration: underline;
}
.cat_title:hover {
  color: #141259 !important;
}
.reply_count {
  padding-right: 5px;
}
li.noti_ul b {
  display: none;
}
.create_box.connect_wallet_box {
  width: auto;
  min-height: 250px;
  margin-bottom: 30px;
  margin-right: 0;
}
.create_box.connect_wallet_box p {
  color: #000;
}
.create_box.connect_wallet_box:hover {
  border: 2px solid #d2d2d2;
}
.share_nft_modal {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.share_nft_modal a {
  margin: 0 20px;
}
.share_nft_modal i {
  font-size: 24px;
  color: #969696;
}
.share_nft_modal p {
  font-size: 14px;
  color: #b4b4b4;
  margin: 0;
}
.share_nft_modal a:hover i,
.share_nft_modal a:hover p {
  color: #141259;
}
.primary_modal .modal-body {
  max-height: calc(100vh - 200px);
  overflow: auto;
}
button.cancel_btn {
  background-color: #eeeeee;
  color: #a5a5a5;
  font-size: 16px;
  text-transform: capitalize;
  transition: 0.2s all;
  border-radius: 30px;
  padding: 15px;
  height: 40px;
}
.wallet_drop .noti_parent {
  padding: 0 15px;
}
.wallet_balance h4 {
  color: #666;
  margin: 0;
  font-size: 15px;
}
.wallet_balance p {
  color: #000;
  margin: 0;
  font-weight: 600;
}
.wallet_drop .noti_parent a {
  padding: 5px 0;
}
.wallet_address {
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  text-overflow: ellipsis;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
}
.filter_panel h2 {
  font-size: 18px;
  color: #000;
  margin: 0;
}
button.filter_btn {
  color: #a8a8a8 !important;
  font-size: 16px;
  border: 1px solid #a8a8a8 !important;
  border-radius: 5px;
  text-transform: capitalize;
  padding: 5px 25px !important;
  margin: 8px;
  transition: all 0.2s;
}
button.filter_btn.selected,
button.filter_btn:hover {
  background-color: #141259 !important;
  border: 1px solid #141259 !important;
  color: #fff !important;
}
.input_group {
  position: relative;
}
.choose_price {
  border-left: 0;
  position: absolute;
  right: 0;
  width: 100px;
  padding-right: 0;
  width: 60px;
  padding-right: 0;
  border-radius: 0;
  top: -5px;
}
.short_address {
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}
.countdown_panel_div.countdown_panel_div_info {
  position: relative;
  justify-content: center;
  top: unset;
  left: unset;
}
#detai_div .dropdown-menu.show {
  left: unset !important;
  right: 0;
  -webkit-transform: unset !important;
  transform: unset !important;
}
.info_dashcontent {
  padding: 0 !important;
}
.custom_react_select {
  width: 200px;
}
.create_form .custom_react_select {
  width: 100%;
}
.item audio {
  width: 100%;
}
img.music_img {
  height: 140px;
  margin-bottom: 10px;
}
img.music_img_info {
  width: 300px;
  margin-bottom: 15px;
}
.music_img_info_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.topSearchPopup {
  position: absolute;
  z-index: 99;
  background: #fff;
  box-shadow: -4px 5px 10px 2px rgb(0 0 0 / 20%);
  width: 94%;
  left: 15px;
  padding: 10px;
  top: 42px;
}
.search_filter_txt h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
.search_filter_txt p {
  font-size: 14px;
}
.Item_list h3 {
  font-size: 18px;
  margin: 5px 10px;
}
.st-btn {
  display: inline-block !important;
}
.rdtPicker {
  width: auto !important;
}
#calendar_modal .modal-content,
#calendar_modal_expire .modal-content {
  background: #fff;
}
.countdown_panel_div.countdown_panel.ended {
  left: 123px;
}
.ticket_details {
  text-align: center;
  background: #ffffff;
  padding: 35px 0;
  width: 400px;
  margin: 20px auto 0;
  box-shadow: 1px 0px 10px 4px rgb(0 0 0 / 20%);
  border-radius: 10px;
}
.ticket_details h2 {
  font-size: 32px;
  font-weight: 600;
}
.buy_ticket_notes {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  margin: 15px 0 0;
}
.timer_panel {
  font-size: 28px;
  font-weight: 700;
  margin-right: 10px;
  color: #17a0bb;
}
.timer_panel > span {
  margin: 0 5px;
}
.timer_time {
  margin-right: 3px;
}
.get_tickets_section h3 {
  font-size: 18px;
  font-weight: 700;
}
.card_draw .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.draw_accordian .card-header {
  justify-content: center;
}
.draw_accordian .card-header button {
  color: #fff !important;
  font-size: 18px;
  font-weight: 600;
}
.draw_accordian_panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 30px 0 0;
}
.draw_accordian_panel_single {
  width: 140px;
  margin: 15px;
}
.draw_accordian_panel_single h4 {
  color: #141259;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}
.draw_accordian_panel_single h3 {
  color: #17a0bb;
  margin: 0;
  font-weight: 600;
  font-size: 24px;
}
.draw_accordian_panel_single p {
  margin: 0;
}
.finished_rounds .nav-pills {
  background: #cdcccc;
  border-radius: 30px;
  padding: 0;
}
.finished_rounds .nav-pills .nav-item .nav-link {
  font-size: 16px;
}
.finished_rounds .nav-pills .nav-item .nav-link.active {
  background-color: #14125a;
  box-shadow: 0 5px 20px 0px rgb(0 0 0 / 20%),
    0 13px 24px -11px rgb(20 18 90 / 60%);
}
input.round_inp {
  border-radius: 30px;
  border: 1px solid #14125a;
  padding: 5px 15px;
  width: 80px;
  text-align: center;
}
.rounds_btns button {
  min-width: initial;
  font-size: 24px;
  padding: 0 10px;
}
.winning_series {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.winning_series_panel {
  position: relative;
  margin: 5px 10px;
}
.winning_series_panel p {
  font-size: 36px;
  font-weight: 600;
  position: absolute;
  color: rgb(0, 0, 0);
  text-shadow: white -0.75px -0.75px 0px, white 0.75px -0.75px 0px,
    white -0.75px 0.75px 0px, white 0.75px 0.75px 0px;
  transform: rotate(13deg);
  top: 20px;
  left: 25px;
}
.how_play_panel {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px 0px 10px 4px rgb(0 0 0 / 20%);
  min-height: 250px;
  margin-bottom: 30px;
}
#view_winning_tickets .winning_series_panel p {
  font-size: 20px;
  top: 5px;
  left: 14px;
}
#view_winning_tickets .winning_series_panel {
  margin: 5px;
}
.winning_rounds {
  display: flex;
  justify-content: space-around;
  border: 1px solid #172465;
  border-radius: 20px;
  padding: 6px;
}
.winning_round_selected {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 30px;
  display: flex;
  justify-content: center;
}
.prize_details {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0 0 3px 1px rgb(0 0 0 / 51%);
}
.prize_details > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.prize_details > div h2 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  color: #172465;
  margin-bottom: 10px;
}
.prize_details ul {
  list-style: none;
  padding: 0;
}
.prize_details li {
  display: flex;
  justify-content: space-between;
  color: #17a0bb;
  font-size: 14px;
  font-weight: 600;
}